<template>
  <div style="padding-bottom: 50px">

  </div>
</template>

<script>

</script>

<style lang="scss">
  .card-header {
    height: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .box-card {
    width: 30%;
  }
  .title{
    font-size: 16px;
    font-weight: 600;
  }
  .spbh{
    color: #409eff;
    position: relative;
    float: right;
    top: -60px;
    span{
      margin-right: 10px; 
      margin-left: 10px;
      img{
        position: relative;
        top:7px
      }
    }
  }
</style>
<style>
.el-descriptions__label:not(.is-bordered-label){
  font-size: 14px;
  font-weight: 600;
  color: #2c3e50;
}
.el-descriptions__title {
  font-size: 18px;
}
i:hover{
  color: #409eff;
}
.has-gutter{
  color: #fff !important;
}
</style>